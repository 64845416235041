import { Button } from '~/shared/components';
import { FiltersDrawer } from '../FiltersDrawer';
import Filter from '$icons/filter.svg';
import {
    StyledDropdownFiltersBar,
    FilterButtons,
    StyledProductCategories,
    StyledFilterButton,
} from './styled';
import { useProductsStore } from '../../hooks';
import { ProductCategoryList } from '../ProductCategoryList';
import { useTranslation } from '~/shared/utils/translation';
import { TranslationKey } from '~/lib/data-contract';
import { Facet } from '../../../model';
import { EventBus } from '~/shared/utils/event-bus/event-bus';
export type DropdownFiltersBarProps = {
    showUserFilters?: boolean;
    showCategoryOverview?: boolean;
    showEmptyCategories?: boolean;
};

export const DropdownFiltersBar = ({
    showUserFilters = true,
    showCategoryOverview = true,
    showEmptyCategories,
}: DropdownFiltersBarProps) => {
    const {
        facets,
        getSelectedFilterCount,
        setShowFiltersDrawer,
        facetButtons,
        hiddenFacets,
        activeCategory,
    } = useProductsStore();
    const { translate } = useTranslation();

    const onFacetButtonClick = (facet: Facet) => {
        setShowFiltersDrawer(true);
        // This fixes event bus firing before the drawer is open
        setTimeout(() => {
            EventBus.emit('AccordionOpen', `FacetAccordion_${facet.attribute}`);
            EventBus.emit('AccordionScrollTo', `FacetAccordion_${facet.attribute}`);
        }, 0);
    };

    return (
        <StyledDropdownFiltersBar>
            {showCategoryOverview && (
                <StyledProductCategories>
                    <ProductCategoryList
                        scopedCategory={activeCategory}
                        showEmptyCategories={showEmptyCategories}
                    />
                </StyledProductCategories>
            )}
            {showUserFilters && (
                <FilterButtons>
                    {facets
                        .filter(
                            (facet) =>
                                facetButtons.includes(facet.attribute) &&
                                !hiddenFacets.includes(facet.attribute),
                        )
                        .sort((a, b) => {
                            return (
                                facetButtons.indexOf(a.attribute) -
                                facetButtons.indexOf(b.attribute)
                            );
                        })
                        .map((facet) => {
                            const selectCount = getSelectedFilterCount(facet);
                            return (
                                <StyledFilterButton
                                    key={facet.label}
                                    variant={selectCount > 0 ? 'Transparent' : 'Ghost'}
                                    disableHoverAnimation={true}
                                    type={'button'}
                                    onClick={() => onFacetButtonClick(facet)}
                                >
                                    {translate(
                                        `Kompan.Commerce.Filters${facet.label}` as TranslationKey,
                                        facet.label,
                                    )}
                                    {selectCount > 0 && ` (${selectCount})`}
                                </StyledFilterButton>
                            );
                        })}
                    <Button
                        variant="Ghost"
                        onClick={() => setShowFiltersDrawer(true)}
                        disableHoverAnimation
                        icon={<Filter />}
                    >
                        {translate('Kompan.Commerce.ManyMoreButton')}
                    </Button>
                    <FiltersDrawer />
                </FilterButtons>
            )}
        </StyledDropdownFiltersBar>
    );
};
