import { DropdownItem } from '~/shared/components';
import { Facet } from '$templates/blocks/components/M140ProductsList';

export type SpaceArea = {
    SafetyZoneAreaWidth?: number;
    SafetyZoneAreaLength?: number;
};

type HandleBooleanFacetArgs = {
    facet: Facet;
    isChecked: boolean;
    toggleBooleanFacetOption: (attribute: string, isChecked: boolean) => void;
    trackCategoryFilter: (options: { filter_option: string }) => void;
};

type HandleMultiCheckboxFacetArgs = {
    facet: Facet;
    item?: DropdownItem;
    toggleFacetOption: (attribute: string, item: DropdownItem) => void;
    trackCategoryFilter: (options: { filter_option: string }) => void;
};

type HandleMultiRangeFacetArgs = {
    facet: Facet;
    item?: DropdownItem;
    setSelectOptions: (attribute: string, options: DropdownItem[]) => void;
    trackCategoryFilter: (options: { filter_option: string }) => void;
};

type HandleSpaceFacetArgs = {
    facet: Facet;
    item?: DropdownItem;
    spaceArea?: SpaceArea;
    removeFacetSelection: (attribute: string) => void;
    setSelectOptions: (attribute: string, options: DropdownItem[]) => void;
    trackCategoryFilter: (options: { filter_option: string }) => void;
};

export const handleBooleanFacet = ({
    facet,
    isChecked,
    toggleBooleanFacetOption,
    trackCategoryFilter,
}: HandleBooleanFacetArgs) => {
    toggleBooleanFacetOption(facet.attribute, isChecked);
    isChecked &&
    trackCategoryFilter({
        filter_option: facet.attribute,
        });
};

export const handleMultiCheckboxFacet = ({
    facet,
    item,
    toggleFacetOption,
    trackCategoryFilter,
}: HandleMultiCheckboxFacetArgs) => {
    if (!item) return;
    toggleFacetOption(facet.attribute, item);
    trackCategoryFilter({
        filter_option: `${facet.attribute}:${item.label}`
    });
};

export const handleMultiRangeFacet = ({
    facet,
    item,
    setSelectOptions,
    trackCategoryFilter,
}: HandleMultiRangeFacetArgs) => {
    if (!item) return;
    setSelectOptions(facet.attribute, [item]);
    trackCategoryFilter({
        filter_option: `${facet.attribute}:${item.label}`,
    });
};

export const handleSpaceFacet = ({
    facet,
    item,
    spaceArea,
    removeFacetSelection,
    setSelectOptions,
    trackCategoryFilter,
}: HandleSpaceFacetArgs) => {
    console.log('LO_ITEM', {item, facet, spaceArea }); 

    const lang = facet?.attribute?.match(/_(.+)/)?.[0] || '';
    
    if (item) {
        removeFacetSelection(`SafetyZoneAreaLength${lang}`);
        removeFacetSelection(`SafetyZoneAreaWidth${lang}`);
        setSelectOptions(facet.attribute, [item]);

        trackCategoryFilter({
            filter_option: `${facet.attribute}:${item?.label}`
        });
    }

    if (spaceArea?.SafetyZoneAreaWidth || spaceArea?.SafetyZoneAreaLength) {
        removeFacetSelection(`SafetyZoneArea${lang}`);

        const widthValue = Number(spaceArea?.SafetyZoneAreaWidth) + 1 || 1;
        const lengthValue = Number(spaceArea?.SafetyZoneAreaLength) + 1 || 1;

        setSelectOptions(`SafetyZoneAreaWidth${lang}`, [
            {
                value: {
                    lowerBoundInclusive: 1,
                    upperBoundExclusive: widthValue,
                },
                label: `[1 - ${widthValue}]`,
            },
            {
                value: {
                    lowerBoundInclusive: 1,
                    upperBoundExclusive: lengthValue,
                },
                label: `[1 - ${lengthValue}]`,
            },
        ]);
        setSelectOptions(`SafetyZoneAreaLength${lang}`, [
            {
                value: {
                    lowerBoundInclusive: 1,
                    upperBoundExclusive: lengthValue,
                },
                label: `[1 - ${lengthValue}]`,
            },
            {
                value: {
                    lowerBoundInclusive: 1,
                    upperBoundExclusive: widthValue,
                },
                label: `[1 - ${widthValue}]`,
            },
        ]);

        trackCategoryFilter({
            filter_option: `${facet.attribute}:${item?.label}`
        });
    }
};
